import React, { useEffect } from "react";
import { navigate } from "gatsby";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import { clearToken } from "../lib/user.auth";

const Dashboard = () => {
  useEffect(() => {
    clearToken();
    navigate("/");
  }, []);

  return (
    <Layout>
      <h1>Logout</h1>
    </Layout>
  );
};

export default Dashboard;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
